import { useState } from "react"
import useWebSocket from "react-use-websocket"

import { Switch } from "@material-ui/core"

import { ChatBox } from "src/components/Logbox/ChatBox"
import { MTextField } from "src/ui/MTextField/MTextField"

const ECHO_SERVER = `wss://ws.iokit.net/term`

export function ChatDemo({
  serverUri = ECHO_SERVER,
  channel = "chat",
  showSmoothScrollToggle,
}: {
  serverUri?: string
  channel?: string
  showSmoothScrollToggle?: boolean
}) {
  const [chatLog, setChatLog] = useState<string[]>([""])
  const [smoothScroll, setSmoothScroll] = useState(true)
  const [username, setUsername] = useState("")

  const chatUri = `${serverUri}/${channel}`

  const chatWs = useWebSocket(
    chatUri,
    {
      onOpen: () =>
        setChatLog([...chatLog, `[You are connected to ${channel}]`]),
      onClose: () =>
        setChatLog([...chatLog, `[You are disconnected from ${channel}]`]),
      shouldReconnect: (_closeEvent) => true,
      onMessage(event) {
        const message = event.data
        setChatLog([...chatLog, message])
      },
    },
    !!username
  )

  function appendMessage(text: string) {
    if (!text) return
    chatWs.sendMessage(text)
  }

  return (
    <article>
      <div style={{ display: "grid", gap: "1rem" }}>
        {username ? (
          <>
            <ChatBox
              username={username}
              onSend={appendMessage}
              chatLog={chatLog}
              smoothScroll={smoothScroll}
            />

            <div hidden={!showSmoothScrollToggle}>
              <Switch
                id="id-smooth"
                checked={smoothScroll}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                  const newVal = Boolean(ev.target.checked) ? true : false
                  setSmoothScroll(newVal)
                }}
              />
              <label htmlFor="id-smooth">Smooth scrolling</label>
            </div>

            <button onClick={() => setUsername("")}>Quit</button>
          </>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault()
              // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
              const name: string = (e as any).target?.[0].value
              setUsername(name)
            }}
          >
            <p>Input user name then hit 'Enter' to start chatting!</p>
            <MTextField label="Username" required />
          </form>
        )}
      </div>
    </article>
  )
}
