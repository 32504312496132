import { useState } from "react"
import styled from "styled-components"

import { LogBox } from "src/components/Logbox/LogBox"

export function ChatBox({
  smoothScroll,
  onSend,
  chatLog,
  username,
}: {
  chatLog: string[]
  onSend: (s: string) => void
  smoothScroll?: boolean
  username: string
}) {
  const [text, setText] = useState("")

  function handleSend() {
    onSend(`${username}: ${text}`)
    setText("")
  }

  return (
    <div>
      <LogBox log={chatLog} smoothScroll={smoothScroll} rows={10} />

      <InputBar>
        <input
          value={text}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              handleSend()
            }
          }}
          onInput={(e) => setText(e.currentTarget.value)}
        />
        <button disabled={!text.length} onClick={handleSend}>
          Send
        </button>
      </InputBar>
    </div>
  )
}

const InputBar = styled.div`
  display: flex;
  margin-top: 4px;

  & > input {
    flex-grow: 1;
  }
`
