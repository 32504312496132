import { useEffect, useRef } from "react"
import styled from "styled-components"

export function LogBox({
  smoothScroll,
  log,
  rows,
}: {
  log: string[]
  smoothScroll?: boolean
  rows?: number
}) {
  const chatLogElem = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (!log.length) return
    const logElem = chatLogElem?.current
    if (logElem) {
      logElem.scroll({
        top: logElem.scrollHeight,
        behavior: smoothScroll ? "smooth" : "auto",
      })
    }
  }, [log.length, smoothScroll])

  return (
    <TextArea ref={chatLogElem} rows={rows} value={log.join("\n")} readOnly />
  )
}

const TextArea = styled.textarea`
  display: block;
  width: 100%;
  padding: 4px;
`
